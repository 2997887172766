<template>
  <div>
    <div class="flix-capacity"><flixIcon id="user" /> {{ capacity[1] }} / {{ capacity[2] }} ({{ capacity[3] }}%)</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    entry: Object
  },
  data () {
    return {
      capacity: 0,
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getCapacity () {
      this.$flix_post({
        url: 'booking/check_date',
        data: {
          user: this.variables.user.md5_id,
          ID: this.entry.form,
          begin: this.entry.begin,
          end: this.entry.end
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.capacity = ret.data[1]
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getCapacity()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-capacity
    font-size: 9pt
    margin-top: 5px
</style>
